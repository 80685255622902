import React, {useCallback, useMemo, useState} from "react";
import ItemTypes from "./item-types";
import {FormattedMessage} from "react-intl";

let defaults = {};
defaults[ItemTypes.TITULO] = [
    {key: 'vt', pos: 'null', type: ItemTypes.TITULO, html: <FormattedMessage id="vt" />},
    {key: 'bt', pos: 'null', type: ItemTypes.TITULO, html: <FormattedMessage id="bt" />},
    {key: 'lt', pos: 'null', type: ItemTypes.TITULO, html: <FormattedMessage id="lt" />},
    {key: 'at', pos: 'null', type: ItemTypes.TITULO, html: <FormattedMessage id="at" />},
    {key: 'mt', pos: 'null', type: ItemTypes.TITULO, html: <FormattedMessage id="mt" />}
];
defaults[ItemTypes.FRASE0] = [
    {key: 'b0', pos: 'null', type: ItemTypes.FRASE0, html: <FormattedMessage id="b0" />},
    {key: 'm0', pos: 'null', type: ItemTypes.FRASE0, html: <FormattedMessage id="m0" />},
    {key: 'a0', pos: 'null', type: ItemTypes.FRASE0, html: <FormattedMessage id="a0" />},
    {key: 'l0', pos: 'null', type: ItemTypes.FRASE0, html: <FormattedMessage id="l0" />},
    {key: 'v0', pos: 'null', type: ItemTypes.FRASE0, html: <FormattedMessage id="v0" />},
];
defaults[ItemTypes.FRASE1] = [
    {key: 'v1', pos: 'null', type: ItemTypes.FRASE1, html: <FormattedMessage id="v1" />},
    {key: 'l1', pos: 'null', type: ItemTypes.FRASE1, html: <FormattedMessage id="l1" />},
    {key: 'm1', pos: 'null', type: ItemTypes.FRASE1, html: <FormattedMessage id="m1" />},
    {key: 'b1', pos: 'null', type: ItemTypes.FRASE1, html: <FormattedMessage id="b1" />},
    {key: 'a1', pos: 'null', type: ItemTypes.FRASE1, html: <FormattedMessage id="a1" />},
];
defaults[ItemTypes.FRASE2] = [
    {key: 'v2', pos: 'null', type: ItemTypes.FRASE2, html: <FormattedMessage id="v2" />},
    {key: 'a2', pos: 'null', type: ItemTypes.FRASE2, html: <FormattedMessage id="a2" />},
    {key: 'm2', pos: 'null', type: ItemTypes.FRASE2, html: <FormattedMessage id="m2" />},
    {key: 'l2', pos: 'null', type: ItemTypes.FRASE2, html: <FormattedMessage id="l2" />},
    {key: 'b2', pos: 'null', type: ItemTypes.FRASE2, html: <FormattedMessage id="b2" />},
];
defaults[ItemTypes.FRASE3] = [
    {key: 'b3', pos: 'null', type: ItemTypes.FRASE3, html: <FormattedMessage id="b3" />},
    {key: 'l3', pos: 'null', type: ItemTypes.FRASE3, html: <FormattedMessage id="l3" />},
    {key: 'm3', pos: 'null', type: ItemTypes.FRASE3, html: <FormattedMessage id="m3" />},
    {key: 'a3', pos: 'null', type: ItemTypes.FRASE3, html: <FormattedMessage id="a3" />},
    {key: 'v3', pos: 'null', type: ItemTypes.FRASE3, html: <FormattedMessage id="v3" />},
];
defaults[ItemTypes.FRASE4] = [
    {key: 'v4', pos: 'null', type: ItemTypes.FRASE4, html: <FormattedMessage id="v4" />},
    {key: 'b4', pos: 'null', type: ItemTypes.FRASE4, html: <FormattedMessage id="b4" />},
    {key: 'l4', pos: 'null', type: ItemTypes.FRASE4, html: <FormattedMessage id="l4" />},
    {key: 'm4', pos: 'null', type: ItemTypes.FRASE4, html: <FormattedMessage id="m4" />},
    {key: 'a4', pos: 'null', type: ItemTypes.FRASE4, html: <FormattedMessage id="a4" />},
];
defaults[ItemTypes.IMAGEM] = [
    {key: 'ai', pos: 'null', type: ItemTypes.IMAGEM, html: (<img className='figura' src="/img/figuras/a.png" alt="img-a" />)},
    {key: 'vi', pos: 'null', type: ItemTypes.IMAGEM, html: (<img className='figura' src="/img/figuras/v.png" alt="img-v" />)},
    {key: 'bi', pos: 'null', type: ItemTypes.IMAGEM, html: (<img className='figura' src="/img/figuras/b.png" alt="img-b" />)},
    {key: 'li', pos: 'null', type: ItemTypes.IMAGEM, html: (<img className='figura' src="/img/figuras/l.png" alt="img-l" />)},
    {key: 'mi', pos: 'null', type: ItemTypes.IMAGEM, html: (<img className='figura' src="/img/figuras/m.png" alt="img-m" />)}
];

const useCorState = (empty = true) => {
    const initialState = () => {
        const obj = {};
        obj[ItemTypes.TITULO] = empty ? [] : defaults[ItemTypes.TITULO];
        obj[ItemTypes.IMAGEM] = empty ? [] : defaults[ItemTypes.IMAGEM];
        obj[ItemTypes.FRASE0] = empty ? [] : defaults[ItemTypes.FRASE0];
        obj[ItemTypes.FRASE1] = empty ? [] : defaults[ItemTypes.FRASE1];
        obj[ItemTypes.FRASE2] = empty ? [] : defaults[ItemTypes.FRASE2];
        obj[ItemTypes.FRASE3] = empty ? [] : defaults[ItemTypes.FRASE3];
        obj[ItemTypes.FRASE4] = empty ? [] : defaults[ItemTypes.FRASE4];
        return obj;
    };
    const [atual, set] = useState(initialState);

    return {atual, set};
}

const useAppState = () => {

    const b = useCorState();
    const l = useCorState();
    const m = useCorState();
    const v = useCorState();
    const a = useCorState();
    const none = useCorState(false);

    const cores = useMemo(() => ({
        b: b,
        l: l,
        m: m,
        v: v,
        a: a,
        null: none
    }), [b, l, m, v, a, none]);

    const setPosition = useCallback((type, key, from, to) => {
        // É preciso usar {...} pra criar um clone do state atual, se não o react quebra foda
        let fromObj = {...cores[from].atual};
        let toObj = {...cores[to].atual};
        let fromArr = fromObj[type];

        const idx = fromArr.findIndex((i) => i.key === key);
        // O retorno do splice é um array, mesmo estando fazendo splice de 1 item só
        const item = fromArr.splice(idx, 1);

        // É meio redundante ter a posição atual do objeto como uma propriedade dele, mas é a única forma de acessar essa informação no evento de drop, já que não dá pra saber
        // de onde o item está sendo arrastado, só as propriedades dele e do ponto de destino.
        item[0].pos = to;

        fromObj[type] = fromArr;
        toObj[type] = [...toObj[type], ...item];

        cores[from].set(fromObj);
        cores[to].set(toObj);

    }, [cores]);

    const getItens = useCallback((pos, type) => {
        let atual = cores[pos].atual;
        return atual[type];
    }, [cores]);

    const typeIsDone = useCallback((type) => {
        if (!type)
            return true;
        let done = true;
        Object.keys(cores).forEach(i => {
            if (!done)
                return;
            done = !cores[i].atual[type].some((item) => item.pos !== item.key[0]);
        })
        return done;
    }, [cores]);

    const shouldDisplay = useCallback((atual, anterior)  => {
        return (typeIsDone(anterior)) && !typeIsDone(atual);

    }, [typeIsDone]);

    const isCompleted = useCallback(() => {
        let done = true;
        ItemTypes.all.forEach((t) => {
            if (!done)
                return;
            done = typeIsDone(t);
        })

        return done;
    }, [typeIsDone])

    return [getItens, setPosition, shouldDisplay, isCompleted];
}

export default useAppState;