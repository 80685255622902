import React, {useEffect, useState} from "react";
import './App.css';
import useAppState from "./app-state";
import Cookies from 'js-cookie';
import {FormattedMessage, IntlProvider} from "react-intl";

let isTouchDevice = 'ontouchstart' in document.documentElement;

const MobileLayout = React.lazy(() => import("./mobile"));
const DesktopLayout = React.lazy(() => import("./desktop"));

const getLocale = () => {
    const locale = Cookies.get('locale');
    if (!locale) {
        let browserLocale = navigator.language;
        if (browserLocale.includes('en-'))
            browserLocale = 'en';

        if (!browserLocale)
            browserLocale = 'pt-br';
        browserLocale = browserLocale.toLowerCase();
        Cookies.set('locale', browserLocale);
        return browserLocale;
    }

    return locale.toLowerCase();
}

const setLocale = (locale) => {
    Cookies.set('locale', locale);
    window.location.reload();
}

const App = React.memo(() => {
    const [getItens, setPosition, shouldDisplay, isCompleted] = useAppState();
    const [messages, setMessages] = useState(null);
    const locale = getLocale();
    useEffect(() => {
        import(`./messages/${locale}.js`).then((msg) => {
            setMessages(msg.default);
        })
    }, [locale, setMessages]);

    const completed = isCompleted();
    let leave = "";
    switch (locale){
        case "pt-br":
            document.title = "Quebra-Cabeça Hallos Relações";
            leave = 'Tem certeza que gostaria de reiniciar o quebra-cabeça? todas suas respostas serão perdidas.';
            break;
        case  "fr":
            document.title = "Puzzle Hallos";
            leave = 'Êtes-vous sûr de vouloir redémarrer le puzzle ? Toutes vos réponses seront perdues.';
            break;
        case "es":
            document.title = "Rompecabezas Hallos";
            leave = '¿Estás seguro de que deseas reiniciar el rompecabezas? Todas tus respuestas se perderán.';
            break;
        default:
            document.title = "Puzzle Hallos";
            leave = 'Are you sure you want to restart the puzzle? All your answers will be lost.';
            break;
    }

    const onClick = () => {
        if (window.confirm(leave)) {
            window.location.reload(true);
        }
    }

    if (messages === null)
        return '...'

    return (
        <IntlProvider locale={locale} messages={messages}>
                <div className="app" key={locale}>
                    {isTouchDevice ? (
                        <React.Suspense fallback={null}>
                            <MobileLayout titulo={"componente bonitinho"} setPosition={setPosition} getItens={getItens} shouldDisplay={shouldDisplay}
                                          completed={completed} locale={locale} setLocale={setLocale} onClick={onClick}/>
                        </React.Suspense>
                    ) : (
                        <React.Suspense fallback={null}>
                            <DesktopLayout getItens={getItens} setPosition={setPosition} tipo={null} shouldDisplay={shouldDisplay} anterior={null} passo={null}
                                           locale={locale} completed={completed} setLocale={setLocale} onClick={onClick}/>
                        </React.Suspense>
                    )}
                </div>
        </IntlProvider>
    )
})

export default App;
