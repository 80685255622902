const TITULO = 'titulo';
const IMAGEM = 'imagem';
const FRASE1 = 'frase0';
const FRASE2 = 'frase1';
const FRASE3 = 'frase2';
// As frases 0 são as negativas, foram colocadas em penúltimo na ordem a pedido do Marco
const FRASE0 = 'frase3';
const FRASE4 = 'frase4';

const ItemTypes = {
    TITULO,
    IMAGEM,
    FRASE0,
    FRASE1,
    FRASE2,
    FRASE3,
    FRASE4,
    all: [TITULO, IMAGEM, FRASE0, FRASE1, FRASE2, FRASE3, FRASE4]
}

export default ItemTypes;